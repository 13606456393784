const clients = [
  { img: require('../assets/clients/02-1.png').default },
  { img: require('../assets/clients/woodsbagot.png').default },
  { img: require('../assets/clients/05.png').default },
  { img: require('../assets/clients/04.png').default },
  { img: require('../assets/clients/SHK.png').default },
  { img: require('../assets/clients/04-1.png').default },
  { img: require('../assets/clients/01-2.png').default },
  { img: require('../assets/clients/02.png').default },
  { img: require('../assets/clients/LCSD.png').default },
  { img: require('../assets/clients/ASD.png').default },
  // { img: require('../assets/clients/03-1.png').default },
  // { img: require('../assets/clients/DSD.png').default },
  // { img: require('../assets/clients/03-2.png').default },
  // { img: require('../assets/clients/02-2.png').default },
  // { img: require('../assets/clients/atkins.png').default },
  // { img: require('../assets/clients/aecom.png').default },
  // { img: require('../assets/clients/01.png').default },
  // { img: require('../assets/clients/CEDD.png').default },
  // { img: require('../assets/clients/03.png').default },
  // { img: require('../assets/clients/05-1.png').default },
  // { img: require('../assets/clients/00-2.png').default },
  // { img: require('../assets/clients/00-1.png').default },
  // { img: require('../assets/clients/dennis.png').default },
  // { img: require('../assets/clients/EMSD.png').default },
  // { img: require('../assets/clients/CKP.png').default },
  // { img: require('../assets/clients/00.png').default },
  // { img: require('../assets/clients/01-1.png').default },
];

export default clients;
