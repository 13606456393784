import { useState, forwardRef } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Section, SectionHead, SectionTitle } from './Common';
import { useSpring, animated } from 'react-spring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert'

import contact from '../contents/contact';

const AnimatedFormGroup = animated(Form.Group);
const AnimatedButton = animated(Button);

const CompanyAddress = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  max-width: 92%;
  padding-left: 15px;
  padding-right: 15px;

  float: none;
  text-align: center;
  font-weight: 300;
  color: #888;
  font-size: 16px;
`;

const ContactDetailsBanner = styled.div`
  width: 100%;
  background-color: #fff;
  margin-bottom: 50px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.07);
`;

const ContactDetailsContainer = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
`;

const ContactDetail = ({icon, children}) => {
  return (
    <div className={css`
      text-align: center;
      font-size: 20px;
      color: #999;
      white-space: nowrap;
    `}>
      <FontAwesomeIcon icon={icon} style={{
        marginRight: '5px',
        verticalAlign: 'middle',
      }} />
      <span>{children}</span>
    </div>
  );
};

const AlertText = styled.span`
  font-size: 18px;
`;

const formInput = css`
  font-size: 18px;
  line-height: 1.33;
`;

const formInputMessage = css`
  font-size: 18px;
  line-height: 1.33;
  resize: none;
`;

const submitButtonStyle = {
  backgroundColor: '#d95c5c',
  borderColor: '#d95c5c',
  color: '#fff',
  cursor: 'pointer',
  textTransform: 'uppercase',
}

const ContactUs = forwardRef(({fadeIn}, ref) => {

  const [showSendSuccess, setShowSendSuccess] = useState(false);
  const [showSendFail, setShowSendFail] = useState(false);

  const openAnimationDelay = 350;

  const leftSpring = useSpring({
    from: { opacity:0, x:-300 },
    to: fadeIn ?  { opacity:1, x:0 } : { opacity:0, x:-300 },
    delay: openAnimationDelay,
  });
  const rightSpring = useSpring({
    from: { opacity:0, x: 300 },
    to: fadeIn ?  { opacity:1, x:0 } : { opacity:0, x: 300 },
    delay: openAnimationDelay + 200,
  });
  const buttonSpring = useSpring({
    from: { opacity:0, y:-300 },
    to: fadeIn ?  { opacity:1, y:0 } : { opacity:0, y: 300 },
    delay: openAnimationDelay + 400,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowSendSuccess(false);
    setShowSendFail(false);

    const formData = new FormData(event.target);

    axios.post('/siteQuery.php',
      formData,
      // { headers: formData.getHeaders() },
    ).then(response => {
      // console.log("this is the response: ",response)
      if (response.data === 'success') { setShowSendSuccess(true); }
      else                             { setShowSendFail(true); }
    }).catch(error => {
      setShowSendFail(true);
    });
  };


  return (
    <Section id="contact" ref={ref} style={{ backgroundColor: '#f6f6f6' }}>

      <SectionHead>
        <SectionTitle>Contact <b>Us</b></SectionTitle>
        <CompanyAddress>{contact.address}</CompanyAddress>
      </SectionHead>

      <ContactDetailsBanner>
        <ContactDetailsContainer>
          <Row>
            <Col lg={4} md={12} sm={12} xs={12}>
              <ContactDetail icon={faMapMarkerAlt}>{contact.location}</ContactDetail>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <ContactDetail icon={faPhone}>{contact.tel}</ContactDetail>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <ContactDetail icon={faEnvelope}>{contact.email}</ContactDetail>
            </Col>
          </Row>
        </ContactDetailsContainer>
      </ContactDetailsBanner>

      <Container fluid>
        <Col lg={{ span:8, offset:2 }}>
          
          { showSendSuccess && <Alert variant={'success'}><AlertText>Thank you!  We will contact you as soon as possible.</AlertText></Alert> }
          
          { showSendFail    && <Alert variant={'danger'}><AlertText>Oops, there is a server error.  Please contact us through email directly.</AlertText></Alert> }

          <Form onSubmit={handleSubmit} style={{textAlign:'right'}}>
            <Row>
              <Col md={5} sm={5} xs={12}>
                <AnimatedFormGroup style={leftSpring}><Form.Control name="name"  size="lg" type="text"  placeholder="Full Name" className={formInput} /></AnimatedFormGroup>
                <AnimatedFormGroup style={leftSpring}><Form.Control name="email" size="lg" type="email" placeholder="Email" className={formInput} /></AnimatedFormGroup>
                <AnimatedFormGroup style={leftSpring}><Form.Control name="phone" size="lg" type="text"  placeholder="Phone" className={formInput} /></AnimatedFormGroup>
              </Col>
              <Col md={7} sm={7} xs={12}>
                <AnimatedFormGroup style={rightSpring}>
                  <Form.Control name="message" as="textarea" rows={6} className={formInputMessage} style={{height:'165px'}} />
                </AnimatedFormGroup>
              </Col>
            </Row>
            <AnimatedButton type="submit" style={{ ...submitButtonStyle, ...buttonSpring }}>SEND MESSAGE</AnimatedButton>
          </Form>
        </Col>
      </Container>
    </Section>
  )
});

export default ContactUs;
