import { useState, forwardRef } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { useSpring, useTrail, animated, config } from 'react-spring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { Section, SectionHead, SectionTitle, Spacer } from './Common';

import services from '../contents/services';

const AnimatedFontAwesomeIcon = animated(FontAwesomeIcon);

const ServiceBoxContainer = styled.div`
  margin-left: -15px;
  margin-right: -15px;
`;

const serviceBoxClass = css`
  margin-top: 30px;
  padding: 45px 20px 20px 20px;
  border: 1px solid #ececec;
  border-radius: 4px;
  text-align: center;
  position: relative;
`;

const ServiceIcon = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -60px;
  margin: auto;

  border: 1px solid #ececec;
  background-color: rgba(0,0,0,0);
  border-radius: 50%;

  width: 2em;
  height: 2em;
  line-height: 2em;
  font-size: 4em;

  text-align: center;
  vertical-align: middle;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const serviceIconBackground = css`
  font-size: 1.7em;
`;

const serviceIconContent = css`
  font-size: 0.8em;
`;

const ServiceTitle = styled.h3`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.1;
`;

const ServiceText = styled.p`
  font-size: 15px;
  margin-bottom: 20px;
`;

const ServiceBox = ({ item, containerStyle }) => {

  const [isMouseOver, setIsMouseOver] = useState(false);

  const bgColor = useSpring({
    color: isMouseOver ? '#d95c5c' : '#f6f6f6'
  })

  const iconSprings = useSpring({
    scale: isMouseOver ? 1.1 : 1,
    config: isMouseOver ? { mass: 1, tension: 210, friction: 4 } : config.default,
  })

  return (
    <div className='col-lg-3 col-sm-6 col-xs-12' style={{float:'left'}} onMouseOver={()=>setIsMouseOver(true)} onMouseLeave={()=>setIsMouseOver(false)}>
      <div style={{ marginTop: '25px', marginBottom: window.innerWidth > 992 ? '25px' : '50px '}}>
        <animated.div className={serviceBoxClass} style={ containerStyle }>
          <ServiceIcon>
            <AnimatedFontAwesomeIcon icon={faCircle}  className={serviceIconBackground} style={bgColor} />
          </ServiceIcon>  
          <ServiceIcon>
            <AnimatedFontAwesomeIcon icon={item.icon} className={serviceIconContent} inverse={isMouseOver} style={iconSprings} />
          </ServiceIcon>
          <ServiceTitle>{item.title}<br /></ServiceTitle>
          <ServiceText><br />{item.description}</ServiceText>
        </animated.div>
      </div>
    </div>
  );
}

const Services = forwardRef(({fadeIn}, ref) => {

  const trail = useTrail(services.length, {
    from: { opacity:0, y:100 },
    to: fadeIn ? { opacity:1, y:0 } : { opacity:0, y:100 },
    config: config.slow,
    // delay: 350,
  });

  return (
    <Section id="services" ref={ref} style={{ backgroundColor: 'white' }}>
      <SectionHead>
        <SectionTitle>services</SectionTitle>
        <Spacer height={42} color='#fff' />
        <ServiceBoxContainer>
          {
            trail.map((props, index) => <ServiceBox key={index} item={services[index]} containerStyle={props} />)
          }
        </ServiceBoxContainer>
      </SectionHead>
    </Section>
  )
});

export default Services;
