const works = [

  {
    id: 0,
    image: require('../assets/works/Macau-Museum_thumb-800x530.jpg').default,
    title: 'MACAO MUSEUM – INTERACTIVE PROJECTION',
    category: 'exp space',
    description: 'Crystal CG has created an interactive projection game for the Macao Museum “Deep Blue Treasure – Nanhai I Underwater Archaeological Cultural Relics Exhibition”. It allows participants to design their own Nanhai I, and achieve deep understanding of the history.',
    showType: 'youtube',
    youtubeID: '-oHjjJgCH3s',
  },

  {
    id: 1,
    image: require('../assets/works/HM-Cave-Projection-Programme-for-Dunhuang-Exhibition.mp4_20190529_102326.079-800x530.jpg').default,
    title: 'HONG KONG HERITAGE MUSEUM – DIGITAL DUNHUANG EXHIBITION',
    category: 'exp space',
    description: 'Crystal CG took part in the “Digital Dunhuang – The Story of Heaven & Earth” exhibition. 3D Projection mapping technology has applied to present different Dunhuang stories in one of the simulated grottoes. High quality animation enhanced the visual impact and learning experience to public.',
    showType: 'youtube',
    youtubeID: 'PkCWt4EywHQ',
  },

  {
    id: 2,
    image: require('../assets/works/ECCO-leather-exhibition.mp4_20190529_102145.577-800x530.jpg').default,
    title: 'ECCO – LEATHER EXHIBITION',
    category: 'retail marketing',
    description: 'A highlight of customer’s leading position in leather manufacturing industry. Crystal CG provided one-stop service from interactive elements to fabrication production, aiming at providing a new level of exhibition experience to public',
    showType: 'youtube',
    youtubeID: 'Y4W8FvdAEsI',
  },

  {
    id: 3,
    image: require('../assets/works/WineDine_small.jpg').default,
    title: 'WINE & DINE',
    category: 'retail marketing',
    description: 'The gigantic droplet-shaped canopy projection stand on the West Kowloon Cultural District Waterfront with 13m height above the ground and 14m diameter. The projection videos are especially designed to outstand the theme of Spanish and Italian Night.',
    showType: 'youtube',
    youtubeID: 'U_E1SZ_xHJ8',
  },

  {
    id: 4,
    image: require('../assets/works/RAE_small-1.jpg').default,
    title: 'RAE',
    category: 'retail marketing',
    description: 'Crystal CG participated in the Retail Asia Expo 2015. We shared with the world on how our interactive solutions could be integrated into retailing application, creating the maximum attractiveness and exposure for branding and event promotions. We aimed to develop solutions that match your industry, business and your ideas. Here are the kiosks we showcased in our booth: 1.Shopfront Interactive Kiosk; 2.AR Photobooth; 3.Interactive Display; 4.SDT Accessories Cabinet; 5.Interactive Fitting Mirror',
    showType: 'youtube',
    youtubeID: 'CNDgvCZ-HVk',
  },

  {
    id: 5,
    image: require('../assets/works/SCB_small.jpg').default,
    title: 'SCB',
    category: 'retail marketing',
    description: 'Different check and inspection were carried out for our programming, Crystal Cg has extended its client base to the banking and financial sector.',
    showType: 'slide',
    photos: [
      require('../assets/works/SCB_big1.jpg').default,
      require('../assets/works/SCB_big2.jpg').default,
    ],
  },

  {
    id: 6,
    image: require('../assets/works/NuSkin_small.jpg').default,
    title: 'NU SKIN',
    category: 'retail marketing',
    description: 'Crystal CG has developed a Portable Photo Booth App for NU Skin’s Greater China Regional Convention at Hong Kong Asia World-Expo. The event attendance has reached up to 30,000 people and Crystal CG’s capabilities are no doubt in creating a smartphone app that supports such a large volume usage. The app shows different backgrounds from the NU Skin anniversary celebration.',
    showType: 'youtube',
    youtubeID: 'ac8YeP9rhfI',
  },

  {
    id: 7,
    image: require('../assets/works/Longines_small.jpg').default,
    title: 'LONGINES',
    category: 'retail marketing',
    description: 'Crystal CG has taken part in designing a photo shooting system, for guests to take pictures. Guests could choose one out of the four different race course pictures and use it as background; it could then be sent directly either to their Email or uploaded on to their Facebook account, in order to incorporate with social network exposure.',
    showType: 'slide',
    photos: [
      require('../assets/works/Longines_big2.jpg').default,
      require('../assets/works/Longines_big.jpg').default,
    ],
  },

  {
    id: 8,
    image: require('../assets/works/Lloyd-HKDI-facade-projection_small.jpg').default,
    title: 'HKDI',
    category: 'retail marketing',
    description: 'The 3-minute brand launch show “A Journey of Wisdom” was performed as projection mapping on the façade of the Hong Kong Design Institute (HKDI) building with an image size of 25m (W) x 8m (H) to showcase the advanced craftsmanship of the product line.',
    showType: 'youtube',
    youtubeID: 'YkdXBDKE6aY',
  },

  {
    id: 9,
    image: require('../assets/works/ecco-V2_small.jpg').default,
    title: 'ECCO',
    category: 'retail marketing',
    description: 'A virtual fitting room is positioned in the MTR concourse. Our motion sensing technology detects the body position and brings you to the virtual try-on. Different background themes are applied to match the shoes and accessories of your own choice. Photograph print-out available. With blue screen setting, different background themes are applied to match the shoes and accessories of your own choice.',
    showType: 'youtube',
    youtubeID: 'pn03OMBsxDs',
  },

  {
    id: 10,
    image: require('../assets/works/SCMP_logo_small.jpg').default,
    title: 'BAZAAR',
    category: 'retail marketing',
    description: 'SCMP has approached Crystal CG to design two Interactive photo booths so the guests can keep the photographs representing the night’s event. The first booth was a ‘Billboard’ which integrated the guests into a metropolitan background, and these photographs will then be sent directly to their emails after shooting.',
    showType: 'youtube',
    youtubeID: 'dDL4Y33jSqE',
  },

  {
    id: 11,
    image: require('../assets/works/The-Majesty-of-All-Under-Heaven_small.jpg').default,
    title: 'THE MAJESTY OF ALL UNDER HEAVEN',
    category: 'exp space',
    description: 'The Eternal Realm of China’s First Emperor – Crystal CG finished a range of 2D & 3D animation, Augmented Reality, films and videos for Interactive Multimedia Zone bringing the ancient to life with the demonstration of the process of Qin unify China and illustrate the production procedure of the terracotta warriors.',
    showType: 'youtube',
    youtubeID: 'yQoIxqTqugU',
  },

  {
    id: 12,
    image: require('../assets/works/MoCC_small.jpg').default,
    title: 'MOCC',
    category: 'exp space',
    description: 'Crystal CG is responsible for the production of the exhibition’s content, by adopting both visual and interactive technologies to present the current status of Polar Regions and Global Warming issues.',
    showType: 'youtube',
    youtubeID: 'H8BNgu3qi2k',
  },

  {
    id: 13,
    image: require('../assets/works/DunHuang_showcase_small.jpg').default,
    title: 'DUNHUANG',
    category: 'exp space',
    description: 'Crystal CG’s involvement includes adapting visualization and interactive solutions for the story telling at the exhibition.',
    showType: 'youtube',
    youtubeID: 'RqmIKvICJ_4',
  },

  {
    id: 14,
    image: require('../assets/works/DSD-tour-Guide-system_small-1.jpg').default,
    title: 'DSD',
    category: 'exp space',
    description: 'The Tour Guide system can lead a group of 30 people at once and explains how the treatment works using our system with the smart tablets.',
    showType: 'youtube',
    youtubeID: '2rUPohT8GLA',
  },

  {
    id: 15,
    image: require('../assets/works/SH-CCG_small.jpg').default,
    title: 'VILLA',
    category: 'architectural rendering',
    description: 'Modern luxury villa designs.',
    showType: 'slide',
    photos: [
      require('../assets/works/SH-CCG_big.jpg').default,
      require('../assets/works/Villa_big.jpg').default,
      require('../assets/works/0202_big.jpg').default,
      require('../assets/works/06_big.jpg').default,
    ],
  },

  {
    id: 16,
    image: require('../assets/works/The-Pulse_small.jpg').default,
    title: 'THE PULSE',
    category: 'architectural rendering',
    description: 'The first shopping mall in Hong Kong located by the beach in Repulse Bay.',
    showType: 'slide',
    photos: [
      require('../assets/works/The-Pulse_small.jpg').default,
    ],
  },

  {
    id: 17,
    image: require('../assets/works/Tanner-Hill_small.jpg').default,
    title: 'TANNER HILL',
    category: 'architectural rendering',
    description: 'A new renovated Elderly residential by the Housing Society for Senior Citizen Residences age 60 or above.',
    showType: 'slide',
    photos: [
      require('../assets/works/Tanner-Hill_small.jpg').default,
    ],
  },

  {
    id: 18,
    image: require('../assets/works/Chongqing-Competition_small.jpg').default,
    title: 'CHONGQING COMPETITION',
    category: 'architectural rendering',
    description: 'Concept design for competition.',
    showType: 'slide',
    photos: [
      require('../assets/works/Chongqing-Competition_small.jpg').default,
    ],
  },

  {
    id: 19,
    image: require('../assets/works/DreamHub_small.jpg').default,
    title: 'DREAM HUB',
    category: 'architectural rendering',
    description: 'Styling center as part of the cultural hub for the city.',
    showType: 'slide',
    photos: [
      require('../assets/works/DreamHub_small.jpg').default,
    ],
  },

  {
    id: 20,
    image: require('../assets/works/NKT_small.jpg').default,
    title: 'NKT',
    category: 'architectural rendering',
    description: 'The Hopewell group’s refurbishment project of the Historic building Nam Koo Terrace. The new building will run as a nonprofit wedding venue for the public. By purchasing the land around it, the complex development also includes residential areas, making the layout design more flexible.',
    showType: 'slide',
    photos: [
      require('../assets/works/NKT_small.jpg').default,
    ],
  },

  {
    id: 21,
    image: require('../assets/works/Kai-Tak_small.jpg').default,
    title: 'KAI TAK',
    category: 'architectural rendering',
    description: 'Urban Planning requires lots of complex communication among expertise, Crystal CG assists the government agent to conquer the challenge and contribute in the development of the very last bare land inside the Hong Kong metropolitan.',
    showType: 'slide',
    photos: [
      require('../assets/works/Kai-Tak_small.jpg').default,
    ],
  },

  {
    id: 22,
    image: require('../assets/works/Goldin_small.jpg').default,
    title: 'GOLDIN',
    category: 'architectural rendering',
    description: 'A virtual way of land use suggestions for the Government. A Complex Technology Park Development situated in the heart of Guangzhou City. It consists of a R&D Center, a separate Headquarter, residential area and factories.',
    showType: 'slide',
    photos: [
      require('../assets/works/Goldin_small.jpg').default,
    ],
  },

  {
    id: 23,
    image: require('../assets/works/Double-Cove_small.jpg').default,
    title: 'DOUBLE COVE',
    category: 'architectural rendering',
    description: 'A residential project between the Top developers Henderson, New Word Development and Peterson',
    showType: 'slide',
    photos: [
      require('../assets/works/Double-Cove_small.jpg').default,
    ],
  },

  {
    id: 24,
    image: require('../assets/works/NKT_small_new.jpg').default,
    title: 'NKT',
    category: 'animation',
    description: 'The Hopewell group’s refurbishment project of the Historic building Nam Koo Terrace. The new building will run as a nonprofit wedding venue for the public. By purchasing the land around it, the complex development also includes residential areas, making the layout design more flexible.',
    showType: 'youtube',
    youtubeID: 'UfeJQWx87Uw',
  },

  {
    id: 25,
    image: require('../assets/works/Museum-of-Art_small.jpg').default,
    title: 'MUSEUM OF ART',
    category: 'animation',
    description: 'Crystal CG assisted the Architectural Service Department in the animation to improve the appearance and facilities of the existing Museum of Art, making it the new landmark of Tsim Sha Tsui. The museum had been relocated to the current location for 20 years, and our CG techniques helped to display the new design with its natural surrounding and diversified environment in comparison with the old.',
    showType: 'youtube',
    youtubeID: '3nIe8l8Sv_8',
  },

  {
    id: 26,
    image: require('../assets/works/COD_small1.jpg').default,
    title: 'COD',
    category: 'animation',
    description: 'The first choice of hotel resort for all in one entertainment. The animation introduces the rapid development of the Taipa (Macau) City, and explores the exciting entertainment, the grand design of the accommodation, the global cuisine they offer and a luxurious shopping mall with international brands. The best visualization experience for client.',
    showType: 'youtube',
    youtubeID: 'rANveQ_j0ZE',
  },

];

export default works;