import { useEffect, useLayoutEffect, useRef, useState } from "react";

import Background from './components/Background';
import FirstSection from './components/FirstSection';
import TopMenuBar from './components/TopMenuBar';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Works from './components/Works';
import Clients from './components/Clients';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';

var scrollTimer = null;

let navbarFixed = false;

let loadingPage = document.getElementById("loadingPage");

const App = () => {

  const [fixNavbar, setFixNavbar] = useState(false);
  const [activeSection, setActiveSection] = useState('home');

  const navbarRef = useRef();

  const aboutRef = useRef();
  const [aboutOpen, setAboutOpen] = useState(true);

  const serviceRef = useRef();
  const [serviceOpen, setServiceOpen] = useState(false);

  const worksRef = useRef();
  const [worksOpen, setWorksOpen] = useState(true);
  
  const clientRef = useRef();
  const [clientOpen, setClientOpen] = useState(true);

  const contactRef = useRef();
  const [contactOpen, setContactOpen] = useState(false);

  useEffect(() => {
    if (loadingPage) loadingPage.style.display = "none";
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => { //console.log("[APP] useLayoutEffect");

    const topPos = element => element.getBoundingClientRect().top;

    // ---------------------------------------------------------------------------
    // need to read the following initial position once only, otherwise lagging
    // ---------------------------------------------------------------------------
    const initScrollY = window.scrollY; // console.log('initScrollY = ' + initScrollY);
    let navbarPos = topPos(navbarRef.current); // console.log('navbartop = ' + navbarPos);

    const checkNavBar = () => {
      const scrollTopPos = window.scrollY;
      /**/ if (!navbarFixed && (navbarPos < scrollTopPos - initScrollY)) { navbarFixed = true; setFixNavbar(true); }
      else if ( navbarFixed && (navbarPos > scrollTopPos - initScrollY)) { navbarFixed = false; setFixNavbar(false); }
    }

    const checkSections = () => {
      const windowHeight = window.innerHeight;
      // ---------------------------------------------------------------------------
      // the following cannot read once, (but why?)
      // ---------------------------------------------------------------------------
      let aboutPos   = topPos(aboutRef.current);
      let servicePos = topPos(serviceRef.current);
      let worksPos   = topPos(worksRef.current);
      let clientPos  = topPos(clientRef.current);
      let contactPos = topPos(contactRef.current);

      if (!aboutOpen)   { if (aboutPos < windowHeight) { setAboutOpen(true); } } 
      if (!serviceOpen) { if (servicePos < windowHeight) { setServiceOpen(true); } }
      if (!worksOpen)   { if (worksPos < windowHeight) { setWorksOpen(true); } }
      if (!clientOpen)  { if (clientPos < windowHeight) { setClientOpen(true); } }
      if (!contactOpen) { if (contactPos < windowHeight) { setContactOpen(true); } }

      let currentSection = 'home';
      if (aboutPos <= 10) currentSection = 'about';
      if (servicePos <= 10) currentSection = 'services';
      if (worksPos <= 10) currentSection = 'works';
      // if (clientPos <= 10) currentSection = 'client';
      if (contactPos <= 10) currentSection = 'contact';

      //if (activeSection !== currentSection) 
        setActiveSection(currentSection);

      // console.log('[onScroll] aboutRef(CURRENT) = ' + topPos(aboutRef.current));
      // console.log('[onScroll] contactPos = ' + contactPos);
      // console.log('[onScroll] contactPos(CURRENT) = ' + topPos(contactRef.current));
      // console.log('[onScroll] worksRef(CURRENT) = ' + topPos(worksRef.current));
      // console.log('[onScroll] initScrollY = ' + initScrollY);
      // console.log('[onScroll] scrollTopPos = ' + scrollTopPos);
      // console.log('[onScroll] scrollBottomPos = ' + scrollBottomPos);
      // ---------------------------------------------------------------------------
    };
    
    const onScroll = () => {
      checkNavBar();
      if(scrollTimer !== null) { clearTimeout(scrollTimer); }
      scrollTimer = setTimeout(checkSections, 100);
    };

    window.addEventListener("scroll", onScroll, false);
    return () => { //console.log("[APP] *END* useLayoutEffect");
      window.removeEventListener("scroll", onScroll);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <FirstSection />
      <TopMenuBar ref={navbarRef} fixNavbar={fixNavbar} active={activeSection} />
      <div style={{marginTop: fixNavbar ? 100 : 0}}>
        <AboutUs   ref={aboutRef} />
        <Services  ref={serviceRef} fadeIn={serviceOpen} />
        <Works     ref={worksRef} />
        <Clients   ref={clientRef} />
        <ContactUs ref={contactRef} fadeIn={contactOpen} />
        <Footer />
      </div>
      <Background fixNavbar={fixNavbar} />
    </>
  );
};

export default App;
