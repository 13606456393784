// This is a empty (semi-transparent) section appear on top of the nav bar.
// After mouse scroll up, the nav bar will stick on the top.
import { css } from '@emotion/css';

const FirstSection = () => {
  return (
    <div id="home" className={css`
      height: 500px;
      overflow: hidden;
      position: relative;
      opaicty: 0;
    `} />
  )
};

export default FirstSection;
