// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement

import { useState, useEffect } from 'react';
import { useTransition, animated, config } from 'react-spring'
import { css } from '@emotion/css';
import styled from '@emotion/styled';

import backgroundImg from '../contents/background';

let timer = null;

let imageCache = [];

const FixedContainer= styled.div`
  position: fixed;
  z-index: -999999;
  background-color: white;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
`;

const BGImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const imgCoverFit = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Background = ({fixNavbar}) => {

  const [index, setIndex] = useState(0);

  useEffect(()=>{
    // console.log("[Background] useEffect");

    const preloadAndWaitImages = async(srcArray) => {
      const promises = srcArray.map((src)=>{
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload  = resolve;
          img.onerror = reject;
          img.src = src;
          imageCache[src] = img;
        });
      });
      
      await Promise.all(promises);
      
      timer = window.setInterval(()=>{ setIndex(idx => (idx+1)%backgroundImg.length); }, 6000);
    };

    let bgImages = backgroundImg.map(item => item.img);
    preloadAndWaitImages(bgImages); 

    return () => {
      window.clearInterval(timer); // console.log("[Background] Quit");
    }
    // eslint-disable-next-line
  }, []);

  const transitions = useTransition(index, {
    from:  { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  })

  return (
    <FixedContainer style={{ visibility: fixNavbar ? 'hidden' : 'visible' }}>
      <BGImageContainer style={{backgroundColor: 'rgba(0,0,0,0.2)'}} />
      {
        transitions((values, item) =>
          <BGImageContainer key={backgroundImg[item].id}>
            <animated.img src={backgroundImg[item].img} alt="screen-saver" className={imgCoverFit}  style={values} />
          </BGImageContainer>
        )
      }
    </FixedContainer>
  )
}

export default Background;

