import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Section, SectionHead } from './Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const SocialIconsContainer = styled.div`
  margin-bottom: 10px;
  font-size: 24px;
  text-align: center;
  /* font-weight: 300; */
  /* color: #666; */
`;

const SocialButton = styled.div`
  display: inline-block;

  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  
  margin: 5px;

  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 50%;

  text-align: center;
  vertical-align: middle;

  color: #ccc;

  &:hover {
    border-color: #666;
    color: #666;
  };
`;

const Copyright = styled.div`
  font-size: 15px;
  text-align: center;
  color: #999;
  margin-bottom: 20px;
`;

const SocialIconButton = ({ link, title, icon }) => {
  return (
    <a href={link} title={title} target="_blank" rel="noreferrer">
      <SocialButton>
        <FontAwesomeIcon icon={icon} className={css`
          font-size: 1em;
          pointer-events: none;
        `} />
      </SocialButton>
    </a>
  );
};

const Footer = () => {
  return (
    <Section id="footer" style={{ backgroundColor: 'white', minHeight: 0 }}>
      <SectionHead>

        <SocialIconsContainer>
          <SocialIconButton link={"https://www.youtube.com/channel/UCsmCvnKYSSaort7B-fDvLuQ"} title="YouTube" icon={faYoutube} />
          <SocialIconButton link={"https://www.facebook.com/CrystalCGHK/"} title="Facebook" icon={faFacebookF} />
          <SocialIconButton link={"https://www.linkedin.com/company-beta/13239784"} title="Linkedin" icon={faLinkedinIn} />
        </SocialIconsContainer>

        <Copyright>
          {new Date().getFullYear()} © Crystal Computer Graphics Limited. All rights reserved.
        </Copyright>

      </SectionHead>
    </Section>
  )
};

export default Footer;
