import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { Section, SectionHead, SectionTitle } from './Common';

import aboutUs from '../contents/about-us';

const AboutContent = styled.div`
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  color: #666;
`;

const AboutUs = forwardRef(({}, ref) => {
  return (
    <Section id="about" ref={ref} style={{ backgroundColor: 'white' }}>
      <SectionHead>
        <SectionTitle>About</SectionTitle>
        <AboutContent>{aboutUs}</AboutContent>
      </SectionHead>
    </Section>
  )
});

export default AboutUs;
