import { useState, useEffect, forwardRef } from 'react';
import styled from '@emotion/styled';
import { Section, SectionHead, SectionTitle } from './Common';
import Measure from 'react-measure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import ItemsCarousel from 'react-items-carousel';

import clients from '../contents/clients.js';

let timer = null;

const MesureContainer = styled.div`
  width: 100%;
`;

const CarouselContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 90%;
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 122px;
  text-align: center;
`;

const LogoImage = styled.img`
  height: 100%;
`;

const Clients = forwardRef((props, ref) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [numItems, setNumItems] = useState(1);
  const chevronWidth = 30;

  // User has switched back to the tab
  const onFocus = () => {
    //console.log(''); // why I have to console.log(), otherwise not work?
    if (timer === null) {
      timer = window.setInterval(() => { setActiveItemIndex(idx => idx+1); }, 3000);
    }
  };

  // User has switched away from the tab
  const onBlur = () => {
    //console.log(''); // why I have to console.log(), otherwise not work?
    window.clearInterval(timer); // console.log("[Background] Quit");
    timer = null;
  };

  useEffect(()=>{
    // console.log("[Background] useEffect");
    onFocus();
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    
    return () => {
      // console.log("[Background] Quit");
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      onBlur();
    }
    // eslint-disable-next-line
  }, []);

  const onResize = (contentRect) => {
    let width = contentRect.client.width;
    let nItems = Math.floor(width * 0.9 / 172);
    if (nItems < 1) nItems = 1;
    setNumItems(nItems);
  };

  return (
    <Measure client onResize={onResize} >
      {({ measureRef }) => (
        <Section id="clients" ref={ref} style={{ backgroundColor: 'white' }}>
          <SectionHead>
            <SectionTitle>Clientele</SectionTitle>
          </SectionHead>
          
          <MesureContainer ref={measureRef}>
            <CarouselContainer>
              <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                infiniteLoop={true}
                numberOfCards={numItems}
                gutter={20}
                leftChevron={<FontAwesomeIcon icon={faArrowCircleLeft} size="2x" color="#999" style={{textAlign:'center'}}/>}
                rightChevron={<FontAwesomeIcon icon={faArrowCircleRight} size="2x" color="#999" />}
                outsideChevron={true}
                chevronWidth={chevronWidth}
              >
                {clients.map((item, index) => 
                  <LogoContainer key={index}>
                    <LogoImage src={item.img} alt="logo" />
                  </LogoContainer>
                )}
              </ItemsCarousel>
            </CarouselContainer>
          </MesureContainer>
        </Section>
      )}
    </Measure>
  )
});

export default Clients;
