import { useState, useEffect, useMemo, forwardRef } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { useSpring, useTransition, animated } from 'react-spring'
import Measure from 'react-measure';
import { Section, SectionHead, SectionTitle } from './Common';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel'

import ReactPlayer from 'react-player/';

import works from '../contents/works';

const worksImageWidth  = 400;
const worksImageHeight = 280;

const modalDialogStyle = css`
  width: 95%;
  max-width: 100%;
`;

const modalContentStyle = css`
  background-color: #f6f6f6;
  border-radius: 0.7rem;
`;

const modalHeaderStyle = css`
  border-bottom-width: 0; 
`;

const modalBodyStyle = css`
  padding-top: 0;
  padding-bottom: 3rem;
`;

/* Player ratio: 100 / (1920 / 1080) */
const playerWrapper = css`
  position: relative;
  padding-top: 56.25%
`;

const playerStyle = css`
  position: absolute;
  top: 0;
  left: 0;
`;

/* Slide image ratio: 100 / (770 / 453) */
const slideWrapper = css`
  position: relative;
  padding-top: 58.83%
`;

const slideCarouselStyle = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const slidePhotoStyle = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const itemContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

const itemTitle = css`
  text-align: center;
  text-transform: uppercase;
  font-size: 42px;
  font-weight: 300;
  color: #515151;
  margin-top: 0;
  line-height: 1.1;
`;


const itemCategoryContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px 0;
`;

const itemCategoryText = css`
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #999;
`;

const itemCategoryLine = css`
  background-color: #999;
  margin: 5px 15px;
  flex-grow: 1;
  height: 1px;
  max-width: 90px;
`;

const itemDescription = css`
  text-align: center;
  font-size: 15px;
  color: #333;
  margin-bottom: 20px;
`;

function MyVerticallyCenteredModal({ work, ...props }) {
  let item = work || works[0];
  return (
    <Modal 
    {...props}
    aria-labelledby="contained-modal-title-vcenter" 
    centered
    dialogClassName={modalDialogStyle}
    contentClassName={modalContentStyle}
    >
      <Modal.Header closeButton className={modalHeaderStyle}></Modal.Header>
      <Modal.Body className={modalBodyStyle}>
        <Container>
          <Row >
            <Col xs={12} lg={6}>
            { item.showType === 'youtube' &&
              <div className={playerWrapper}>
                <ReactPlayer
                  className={playerStyle}
                  url={'https://www.youtube.com/watch?v=' + item.youtubeID}
                  width='100%'
                  height='100%'
                />
              </div>
            }
            { item.showType === 'slide' &&
              <div className={slideWrapper}>
                <Carousel 
                  controls={item.photos.length > 1}
                  indicators={item.photos.length > 1}
                  className={slideCarouselStyle}
                >
                  { item.photos.map((photo, index) =>
                    <Carousel.Item key={index}>
                      <img className={slidePhotoStyle} src={photo} alt="slide" />
                    </Carousel.Item>
                  )}
                </Carousel>
              </div>
            }
            </Col>
            <Col xs={12} lg={6}>
              <div className={itemContainer}>
                <div className={itemTitle}>{item.title}</div>
                <div className={itemCategoryContainer}>
                  <div className={itemCategoryLine} />
                  <div className={itemCategoryText}>{item.category}</div>
                  <div className={itemCategoryLine} />
                </div>
                <div className={itemDescription}>{item.description}</div>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      {/* <Modal.Footer> <Button onClick={props.onHide}>Close</Button> </Modal.Footer> */}
    </Modal>
  );
}

const workGridContainer = css`
  position: absolute;
  pointer-events: none;
  padding: 0;
`;

/* work image: 100 / (400 / 280) */
const workImageWrapper = css`
  position: relative;
  padding-top: 70%;
  overflow: hidden;
  pointer-events: auto;
`;

const workImg = css`
  position : absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const workOverlay = css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #d95c5cdd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

const workOverlayTitle = css`
  text-align: center;
  text-transform: uppercase;
  font-size: 23px;
  color: #fff;
  line-height: 1.1;
`;

const workOverlayCategory = css`
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  line-height: 1.42857;
`;

const WorkBox = ({index, work, handleWorkBoxClick}) => {

  const [isMouseOver, setIsMouseOver] = useState(false);

  const overlaySprings = useSpring({
    x: isMouseOver ? 0 : -500,
    opacity: isMouseOver ? 1 : 0,
    config: { mass: 1, tension: 210, friction: 26 },
  })

  return (
    <div 
      className={workImageWrapper}
      onMouseOver={()=>setIsMouseOver(true)} 
      onMouseLeave={()=>setIsMouseOver(false)}
      onClick={()=>handleWorkBoxClick(index)}
    >
      <img className={workImg} alt='work' src={work.image} />
      <animated.div className={workOverlay} style={overlaySprings}>
        <div className={workOverlayTitle}>{work.title}</div>
        <div className={workOverlayCategory}>{work.category}</div>
      </animated.div>
    </div>
  );
}

const FilterButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
`;

const filterButtonStyle = css`
  display: inline-block;
  padding: 8px 12px;
  margin: 5px;
  background-color: #fff;
  border-radius: 4px;
  color: #999;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`;

const FilterButton = ({myFilter, currentFilter, label, handleClick}) => {
  const active = currentFilter === myFilter;
  const [isMouseOver, setIsMouseOver] = useState(false);
  const buttonColor = useSpring({
    backgroundColor : (active || isMouseOver) ? '#d95c5c' : '#fff',
    color           : (active || isMouseOver) ? '#fff' : '#999',
  })
  return (
    <animated.div 
      className={filterButtonStyle} 
      onClick={()=>handleClick(myFilter)} 
      onMouseOver={()=>setIsMouseOver(true)} 
      onMouseLeave={()=>setIsMouseOver(false)}
      style={buttonColor}
    >
      {label}
    </animated.div>
  );
}

let imageCache = [];

const Works = forwardRef((props, ref) => {

  const [modal, setModal] = useState({ id:0, show:false });
  const handleWorkBoxClick = (index) => { setModal({ id:index, show:true }); }

  const [filter, setFilter] = useState("all");
  const [filteredWorks, setFilteredWorks] = useState([]);

  useEffect(()=>{ 
    const preloadImages = async(srcArray) => {
      const promises = srcArray.map((src)=>{
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload  = resolve;
          img.onerror = reject;
          img.src = src;
          imageCache[src] = img;
        });
      });
      await Promise.all(promises);
    };

    let worksImages = works.map(item => item.photos ? item.photos : null).flat().filter(function (el) { return el != null; });
    preloadImages(worksImages); // preload but no need to wait for
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{ 
    setFilteredWorks(() => works.filter((item) => { return filter === 'all' || (item.category === filter) }));
  }, [filter]);

  const [worksGridSize, setWorksGridSize] = useState({ boxWidth: worksImageWidth, boxHeight: worksImageHeight, nCols: 1, scale: 1 });

  const onResize = (contentRect) => {
    let width = contentRect.client.width;
    let nCols = 1;
    if (width > 576) nCols = 2;
    if (width > 992) nCols = 4;
    let boxWidth = width / nCols;
    let boxHeight = boxWidth * worksImageHeight / worksImageWidth;
    let scale = boxWidth / worksImageWidth;
    setWorksGridSize({ boxWidth, boxHeight, nCols, scale });
  };

  const [containerHeight, gridItems] = useMemo(() => {
    let gridItems = filteredWorks.map((work, index) => {
      let col = Math.floor(index % worksGridSize.nCols);
      let row = Math.floor(index / worksGridSize.nCols);
      const x = col * worksGridSize.boxWidth;
      const y = row * worksGridSize.boxHeight;
      return { ...work, x, y, width:worksGridSize.boxWidth, height:worksGridSize.boxHeight }
    });
    let nRows = Math.ceil(filteredWorks.length / worksGridSize.nCols);
    let containerHeight = nRows * worksGridSize.boxHeight;
    return [containerHeight, gridItems];
  }, [worksGridSize, filteredWorks])

  const transitions = useTransition(gridItems, {
    key     : (item) => item.id,
    from    : ({ x, y, width, height }) => ({ x, y, width, height, scale: 0 }),
    enter   : ({ x, y, width, height }) => ({ x, y, width, height, scale: 1 }),
    update  : ({ x, y, width, height }) => ({ x, y, width, height }),
    leave   : ({ x, y, width, height }) => ({ x, y, width, height, scale: 0 }),
    config: { mass: 5, tension: 500, friction: 100 },
    // trail: 25,
  })

  return (
    <Measure client onResize={onResize} >
      {({ measureRef }) => (
        <Section id="works" ref={ref} style={{ backgroundColor: '#f6f6f6' }}>
          <SectionHead>
            <SectionTitle>Works</SectionTitle>
          </SectionHead>

          {/* Filter Button Group */}
          <FilterButtonGroup>
            <FilterButton currentFilter={filter} myFilter="all" handleClick={setFilter} label="all" />
            <FilterButton currentFilter={filter} myFilter="retail marketing" handleClick={setFilter} label="retail & marketing" />
            <FilterButton currentFilter={filter} myFilter="exp space" handleClick={setFilter} label="exp space" />
            <FilterButton currentFilter={filter} myFilter="animation" handleClick={setFilter} label="animation" />
            <FilterButton currentFilter={filter} myFilter="architectural rendering" handleClick={setFilter} label="architectural rendering" />
          </FilterButtonGroup>

          {/* Works images */}
          <div style={{ marginTop:'50px', width:'100%', height:containerHeight+'px'}} ref={measureRef}>
            <div style={{ position:'absolute', width:'100%', height:'100%' }}>
              {
                transitions((values, item) => 
                  <animated.div key={item.id} className={workGridContainer} style={values} >
                    <WorkBox key={item.id} index={item.id} work={item} handleWorkBoxClick={handleWorkBoxClick} /> 
                  </animated.div>
                )
              }
            </div>
          </div>

          {/* Use Modal to display A Work */}
          <MyVerticallyCenteredModal
            show={modal.show}
            work={works[modal.id]}
            onHide={() => setModal({ show:false })}
          />
        </Section>
      )}
    </Measure>
  )
});

export default Works;
