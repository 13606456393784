import { faPlay, faMagic } from '@fortawesome/free-solid-svg-icons';
import { faBuilding, faLightbulb } from '@fortawesome/free-regular-svg-icons';

const services = [
  {
    title: 'ANIMATION',
    description: 'Animation is one area of our expertise which illustrates the atmosphere of properties giving end users the best visual experience.',
    icon: faPlay,
  },
  {
    title: 'ARCHITECTURAL RENDERING',
    description: 'With over 20 years of architectural rendering experience, we have rendered over thousands of images for different architectures around the world. We work closely with our clients to archive their goals in design, planning, land biding and property marketing.',
    icon: faBuilding,
  },
  {
    title: 'MUSEUM & EXPERIENCE SPACE',
    description: 'Combining visual impacts and technology experiences, audience is captivated into an immersive and interactive experience with our cutting edge solutions. Crystal CG provides a Total Solution service consist of creative design, artwork, museum grade fabrication, programming, system integration and maintenance.',
    icon: faMagic,
  },
  {
    title: 'RETAIL & MARKETING SOLUTION',
    description: 'A brand new way to enhance visual merchandising and marketing activities with interactive elements. Promotions are done in an engaging and intriguing way to maximize branding and social media exposure.',
    icon: faLightbulb,
  },
];

export default services;
