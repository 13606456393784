
import styled from '@emotion/styled';

const Section = styled.section`
  min-height: 400px;
  padding-bottom: 70px;
  padding-top: 70px;
  overflow: hidden;
`;

const SectionHead = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px;
`;

const Title = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  max-width: 92%;
  float: none;
`;

const TitleText = styled.h1`
  text-transform: uppercase;
  color: #515151;
  font-size: 48px;
  font-weight: 300;
  text-align: center;
`;

const TitleUnderline = styled.hr`
  width: 90px;
  border-top: 2px solid;
  border-color: #d95c5c;
`;

const SectionTitle = ({children}) => {
  return (
    <Title className="col-md-8 col-sm-10 col-xs-12">
      <TitleText>{children}</TitleText>
      <TitleUnderline />
    </Title>
  );
}

export { Section, SectionHead, SectionTitle };
