import { css } from '@emotion/css';

const Spacer = ({height, color}) => {
  return (
    <div className={css`
      height: ${height}px;
      overflow: hidden;
      position: relative;
      background-color: ${color};
    `} />
  )
};

export default Spacer;
