import { forwardRef } from 'react';
import { css } from '@emotion/css';
import clsx from 'clsx';
import { useSpring, animated, config } from 'react-spring'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar';
import brandImg from '../assets/brand/CCG-Logo-PNG_small-1.png';

const linkStyle = css`
  text-transform: uppercase;
  color: #999;
`;

const activeLinkStyle = css`
  color: #d95c5c;
`;

const TopMenuBar = forwardRef(({fixNavbar, active}, ref) => {
  const logoSprings = useSpring({
    width : fixNavbar ? 26 : 50,
    height: fixNavbar ? 40 : 77,
    config: config.stiff,
  })

  const BrandImg = () => <animated.img src={brandImg} alt="brand" style={logoSprings} />
  
  return (
    <Navbar ref={ref} collapseOnSelect expand="md" bg="white" variant="light" 
      className={css`
        box-shadow: 0 1px 3px rgba(0,0,0,0.12);
      `}
      fixed={fixNavbar ? 'top' : null}
    >
      <Navbar.Brand as={BrandImg}></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Nav>
          <Nav.Link href="#home"     style={{ color: active==='home' ? '#d95c5c' : '#999' }} >Home</Nav.Link>
          <Nav.Link href="#about"    style={{ color: active==='about' ? '#d95c5c' : '#999' }}  >About</Nav.Link>
          <Nav.Link href="#services" style={{ color: active==='services' ? '#d95c5c' : '#999' }}  >Services</Nav.Link>
          <Nav.Link href="#works"    style={{ color: active==='works' ? '#d95c5c' : '#999' }}  >Work</Nav.Link>
          <Nav.Link href="#contact"  style={{ color: active==='contact' ? '#d95c5c' : '#999' }}  >Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
});

export default TopMenuBar;
